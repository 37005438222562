import React from "react"
import { Link } from "react-router-dom"

const links = [
	{
		heading: "company",
		list: [
			{ label: "about us", url: "/about-us" },
			{ label: "contact us", url: "/contact-us" },
			{ label: "profile", url: "/profile" },
			{ label: "Vision", url: "/vision" },
			{ label: "Product Services", url: "/product-services" },
			{ label: "Application Terms", url: "/application-terms" }
		]
	},
	{
		heading: "for plants",
		list: [
			{ label: "gallery", url: "/gallery" },
			{ label: "soil taxonomy", url: "/soil-taxonomy" },
			{ label: "plants nutritional requirement", url: "/plants-nutritional-requirement" }
		]
	},
	{
		heading: "for you",
		list: [
			{ label: "partner with us", url: "/partner-with-us" },
			{ label: "Add business page", url: "/business/form" },
			{ label: "Guide Links", url: "/guide-links" }
		]
	}
]

const Footer = ({ wrapper = "container" }) => {
	return (
		<footer id="footer">
			<div className={wrapper}>
				<div>
					<img src="/images/logo.png" alt="Sharanyu" style={{ width: "200px" }} />
				</div>
				<div id="footer-links-wrapper">
					{links.map((i, idx) => (
						<div key={i.heading + idx}>
							<div className="font-m">
								<span>
									<b>{i.heading}</b>
								</span>
							</div>
							<ul className="font-s">
								{i.list.map((_i, idx) => (
									<Link key={_i.label + idx} to={_i.url}>
										<li>{_i.label}</li>
									</Link>
								))}
							</ul>
						</div>
					))}
					<div id="social-links">
						<div className="font-m">
							<span>
								<b>social links</b>
							</span>
						</div>
						<div className="flex social-networks">
							<Link to={"https://www.facebook.com/Sharanyu01/"} target="blank">
								<img src="/images/facebook.png" alt="Facebook" width={32} height={32} />
							</Link>
							<Link to={"https://www.instagram.com/sharanyu_/"} target="blank">
								<img src="/images/instagram.png" alt="Instagram" width={32} height={32} />
							</Link>
							<Link to={"https://www.youtube.com/@Sharanyu_"} target="blank">
								<img src="/images/youtube.png" alt="Youtube" width={32} height={32} />
							</Link>
						</div>
						<div>
							<Link
								to="https://play.google.com/store/apps/details?id=com.sharanyu&pcampaignid=web_share"
								target="blank"
							>
								<img
									src="/images/playstore.png"
									className="download-link"
									alt="Google Play Store"
									style={{ aspectRatio: 3.4, width: "auto" }}
									height={48}
								/>
							</Link>
							<Link to="">
								<img
									src="/images/appstore.png"
									className="download-link"
									alt="Apple App Store"
									style={{ aspectRatio: 3.4, width: "auto" }}
									height={48}
								/>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</footer>
	)
}

export default Footer
